import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { usePrevious } from "../../hooks/use-previous";
import { Location } from "history";

export const ScrollToTop = withRouter((params: { location: Location<unknown> }) => {
    const previousPathname = usePrevious(params.location.pathname);

    useEffect(() => {
        if (previousPathname !== params.location.pathname) {
            window.scrollTo(0, 0);
        }
    }, [previousPathname, params.location]);

    return <></>;
});
