import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { VerificationFormWithStore } from "../components/Form/VerificationForm";
import { IState } from "../shared/interfaces/state.interface";
import { selectVerificationCode } from "../shared/redux/app.selectors";
import Logo from "../assets/images/logo.svg";
import styled from "styled-components";
import { StickyFooter } from "../components/shared/Footer";
import { useModal } from "../hooks/use-modal";
import { Modal } from "../components/shared/Modal";
import { Color } from "../shared/enums/color.enum";
import { Button, ButtonTheme, ButtonType } from "../components/shared/Button";
import { CodeRequestForm } from "../components/Form/CodeRequestForm";

const CenteredView = styled.div`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-height: calc(100vh - 52px);
    max-width: 280px;
`;

const LogoBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0;

    img {
        width: 190px;
        height: 80px;
    }
`;

const FormWrapper = styled.div`
    position: relative;
    padding-bottom: 100px;

    @media screen and (min-height: 800px) {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
    }
`;

const RequestWrapper = styled.div`
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid ${Color.LightGrey};

    button {
        width: 100%;
    }
`;
interface IVerificationProps {
    verificationCode: string;
}

export const Verification = (props: IVerificationProps) => {
    const { verificationCode } = props;
    const { modalRef, isVisible, toggleVisibility } = useModal();
    const history = useHistory();

    useEffect(() => {
        if (!!verificationCode) {
            history.push("/registration");
        }
    }, [history, verificationCode]);

    return (
        <>
            <Container fluid>
                <CenteredView>
                    <LogoBar>
                        <img src={Logo} alt="Flixcheck GmbH" />
                    </LogoBar>
                    <FormWrapper>
                        <RequestWrapper>
                            <Button
                                label="Jetzt Verifizierungscode anfordern"
                                theme={ButtonTheme.PrimaryFirst}
                                type={ButtonType.Submit}
                                onClick={toggleVisibility}
                            />
                        </RequestWrapper>
                        <VerificationFormWithStore />
                    </FormWrapper>
                </CenteredView>
            </Container>
            <StickyFooter />
            <Modal
                ref={modalRef}
                title="Verifzierungscode anfordern"
                onClose={toggleVisibility}
                show={isVisible}
            >
                <>
                    <p>
                        Bitte geben Sie Ihre E-Mail Adresse ein. Zulässig sind ausschließlich
                        Adressen, die auf <strong>@zuerich</strong>.de enden. Wir senden Ihnen Ihren
                        Verifizierungscode augenblicklich zu.
                    </p>
                    <CodeRequestForm onRequested={toggleVisibility} />
                </>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state)
    };
};

export const VerificationWithStore = connect(mapStateToProps, {})(Verification);
