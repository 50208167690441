import React from "react";
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'
import { IState } from '../../shared/interfaces/state.interface';
import { selectVerificationCode } from '../../shared/redux/app.selectors';

export const ProtectedRoute = ({ 
    component: Component,
    verificationCode, 
    onEnter,
    ...rest }: any) => {

    return (
        <Route 
            {...rest} 
            render={() => {
                if (!verificationCode) {
                    return <Redirect to='/verification' />
                }

                return <Component />;
        }}/>
    )
}

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state)
    }
}

export const ProtectedRouteWithStore = connect(mapStateToProps, null)(ProtectedRoute);